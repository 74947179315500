<template>
  <div id="time-log-page">
    <app-box width="large">
      <template #legend>Time log</template>
      <template #title>{{ cTotalEntries ? 'Manage your time log entries' : 'Get started logging time' }}</template>
      <div class="mb-4 md:mb-6" :class="[cTotalEntries ? ['md:flex md:justify-end'] : []]">
        <p v-if="!cTotalEntries || !minimal" class="text-sm md:text-base text-gray-700 leading-snug">
          <template v-if="cTotalEntries">
            You can log time by using timers on the <router-link to="/tasks" class="underline hover:no-underline">Tasks</router-link> page, or manually on this page.
            Regardless of the method, all time log entries end up here, where you can edit and / or delete them as needed.
          </template>
          <template v-else-if="!minimal">
            You can log time by using timers on the <router-link to="/tasks" class="underline hover:no-underline">Tasks</router-link> page, or manually on this page.
            Give it a try and add your first time log entry by clicking on the button below.
          </template>
        </p>
        <div :class="[cTotalEntries ? [minimal ? ['-mt-28 md:-mt-20 text-center'] : ['mt-4 md:mt-0 md:pl-8 md:self-end']] : [minimal ? ['text-center -mt-28 md:-mt-20'] : ['text-center mt-8']]]">
          <app-button :class="[!minimal ? ['relative md:pl-10'] : []]" type="add" @click="openModal()">
            <svg v-if="!minimal" xmlns="http://www.w3.org/2000/svg" class="hidden md:inline absolute left-4 top-3.5 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span :class="{ 'md:ml-1.5': !minimal}">New time log entry</span>
          </app-button>
        </div>
      </div>
      <div class="-mb-8 md:mb-0" :class="minimal ? ['-mt-1 md:-mt-14'] : []">
        <template v-for="group in cGroups" :key="group.key">
          <app-table v-if="group.entries.length" class="-mx-5 md:mx-0 md:mb-6">
            <app-thead>
              <app-tr>
                <app-th>
                  {{ group.name }}
                  <span class="hidden md:inline ml-0.5 text-gray-500 font-normal">| {{ group.entries.length }} entr{{ group.entries.length !== 1 ? 'ies' : 'y' }}</span>
                  <span class="hidden md:inline ml-0.5 text-gray-500 font-normal">| {{ group.hourCount }} hour{{ group.hourCount !== 1 ? 's' : '' }}</span>
                </app-th>
                <app-th>
                  Time
                </app-th>
                <app-th>
                  Date
                </app-th>
                <app-th />
              </app-tr>
            </app-thead>
            <app-tbody>
              <transition-group move-class="transition-transform duration-500" enter-active-class="transition-colors duration-slow" enter-from-class="bg-yellow-200" enter-to-class="bg-transparent" leave-active-class="transition-opacity duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <tr v-for="entry in group.entries" :id="entry.lastAdded ? 'last-added' : null" :key="entry.uuid">
                  <app-td>
                    <app-td-content>
                      <template #first>{{ entry.itemTitle }}</template>
                      <template #second>
                        {{ entry.projectName }}<span class="hidden md:inline"><span class="mx-1 text-gray-500">|</span>Logged {{ formatTime(entry.createdAt) }}</span>
                      </template>
                    </app-td-content>
                  </app-td>
                  <app-td class="w-20 md:w-24">
                    <app-td-content>
                      <template #single>{{ entry.displayTime }}</template>
                    </app-td-content>
                  </app-td>
                  <app-td class="w-20 md:w-32">
                    <app-td-content>
                      <template #single>{{ formatDate(entry.loggedFor) }}</template>
                    </app-td-content>
                  </app-td>
                  <app-td class="w-1">
                    <app-dropdown :options="entry.options" @click="handleChoice(entry.uuid, $event)" />
                  </app-td>
                </tr>
              </transition-group>
            </app-tbody>
          </app-table>
        </template>
      </div>
      <transition enter-active-class="transition-opacity duration-500" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity duration-500" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <app-modal v-if="showModal" @close="closeModal">
          <template #title>
            {{ editUuid ? "Edit time log entry" : "Add new time log entry" }}
          </template>
          <app-form @submit.prevent="submitForm">
            <app-form-field>
              <app-label id="project-uuid">
                Project
              </app-label>
              <app-select id="project-uuid" v-model="form.fields.projectUuid" :options="cProjects" />
            </app-form-field>
            <app-form-field>
              <app-label id="item">
                Task
                <span class="absolute right-10 mt-1 normal-case">
                  <template v-if="itemFilter == 'active'">
                    <span class="font-medium">active</span>
                    <span class="text-gray-400 mx-1">|</span>
                    <a href="#" class="underline hover:no-underline" @click.prevent="setItemFilter('archived')">archived</a>
                  </template>
                  <template v-if="itemFilter == 'archived'">
                    <a href="#" class="underline hover:no-underline" @click.prevent="setItemFilter('active')">active</a>
                    <span class="text-gray-400 mx-1">|</span>
                    <span class="font-medium">archived</span>
                  </template>
                </span>
              </app-label>
              <app-input id="item" v-model.trim="form.fields.item" :options="cItems" list="items" />
              <p class="mt-1 text-xs text-gray-500 leading-snug">
                Select from the most used {{ itemFilter }} tasks or type in a name to add a new task. New tasks will be set to active and completed.
              </p>
            </app-form-field>
            <div class="flex">
              <app-form-field class="mr-2">
                <app-label id="hour-part">
                  Hours
                </app-label>
                <app-input id="hour-part" v-model="form.fields.hourPart" type="number" min="0" max="99" maxlength="2" oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^0-9]/g,'')" />
              </app-form-field>
              <app-form-field class="mr-2">
                <app-label id="minute-part">
                  Minutes
                </app-label>
                <app-input id="minute-part" v-model="form.fields.minutePart" type="number" min="0" max="59" maxlength="2" oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^0-9]/g,'')" />
              </app-form-field>
              <app-form-field class="w-7/12">
                <app-label id="logged-for">
                  Date
                </app-label>
                <app-input id="logged-for" v-model="form.fields.loggedFor" :class="{'empty-date-input': !form.fields.loggedFor}" type="date" />
              </app-form-field>
            </div>
            <app-button class="mt-3 transition-colors" :disabled="loading || invalidForm">
              {{ editUuid ? "Update" : "Add" }}
            </app-button>
          </app-form>
        </app-modal>
      </transition>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
export default {
  name: "TimeLog",
  data() {
    return {
      editUuid: "",
      entries: [],
      form: {
        fields: {
          hourPart: "",
          loggedFor: "",
          minutePart: "",
          projectUuid: "",
          item: "",
        },
      },
      items: [],
      itemFilter: "active",
      projects: [],
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["focusUuid", "loading", "minimal"]),
    cGroups() {
      const yearAndMonth = (x) => x.loggedFor.replace(/-/g, "").substr(0, 6);
      const months = {};
      this.entries.forEach((x) => (months[yearAndMonth(x)] = true));
      let groups = [];
      for (const key in months) {
        const yearNow = new Date().getFullYear();
        const year = parseInt(key.substr(0, 4));
        const month = U.monthNames()[key.substr(4, 2) - 1];
        const name = year == yearNow ? month : `${month}, ${year}`;
        groups.push({
          key,
          name,
          entries: [...this.entries]
            .map((e) => {
              const item = this.itemsMap.get(e.itemUuid);
              const project = this.projectsMap.get(item.projectUuid);
              e.itemUuid = item.uuid;
              e.itemTitle = item.title;
              e.projectUuid = project.uuid;
              e.projectName = project.name;
              return e;
            })
            .sort((a, b) => {
              let aDate = new Date(a.loggedFor).getTime();
              let bDate = new Date(b.loggedFor).getTime();
              if (aDate !== bDate) return bDate - aDate;
              aDate = new Date(a.createdAt).getTime();
              bDate = new Date(b.createdAt).getTime();
              return bDate - aDate;
            })
            .filter(
              (e) =>
                yearAndMonth(e) === key &&
                (e.projectUuid === this.focusUuid || this.focusUuid === "")
            )
            .map((e, index) => {
              e.options = ["Edit", "Delete"];
              e.displayTime = U.timeFormat(e.minutes);
              return e;
            }),
        });
      }
      groups = groups.map((x) => {
        const totalMinutes = x.entries.reduce((acc, cur) => {
          return (acc += cur.minutes);
        }, 0);
        x.hourCount = U.formatNumber((totalMinutes / 60).toFixed(2));
        return x;
      });
      const sorted = [...groups].sort((a, b) => {
        return a.key < b.key ? 1 : a.key > b.key ? -1 : 0;
      });
      return sorted;
    },
    cTotalEntries() {
      return this.cGroups.reduce((acc, cur) => (acc += cur.entries.length), 0);
    },
    cItems() {
      const isActive = (x) =>
        !x.backlog && (!x.doneOn || x.doneOn === U.localDate());
      const isArchived = (x) => x.doneOn && x.doneOn !== U.localDate();
      const belongsToProject = (x) =>
        x.projectUuid === this.form.fields.projectUuid;
      return this.items
        .map((i) => ({
          ...i,
          count: this.entries.filter((e) => e.itemUuid === i.uuid).length,
        }))
        .sort((a, b) => b.count - a.count)
        .filter(
          (i) =>
            belongsToProject(i) &&
            ((this.itemFilter === "active" && isActive(i)) ||
              (this.itemFilter === "archived" && isArchived(i)))
        )
        .map((i) => i.title)
        .slice(0, 10);
    },
    cProjects() {
      return [...this.projects]
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        })
        .filter(
          (p) =>
            p.active && (p.uuid === this.focusUuid || this.focusUuid === "")
        )
        .map((p) => ({ name: p.name, value: p.uuid }));
    },
    invalidForm() {
      const { hourPart, loggedFor, minutePart, projectUuid, item } =
        this.form.fields;
      if (loggedFor === "" || projectUuid === "" || item === "") return true;
      const noHours = hourPart === "" || hourPart === "0";
      const noMinutes = minutePart === "" || minutePart === "0";
      if (noHours && noMinutes) return true;
      return false;
    },
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    await this.getData();
  },
  methods: {
    formatDate: U.formatDate,
    formatTime: U.formatTime,
    async getData() {
      const [{ entries }, { items }, { projects }] = await U.apiAll([
        { url: "api/entries" },
        { url: "api/items" },
        { url: "api/projects" },
      ]);
      this.entries = entries;
      this.items = items;
      this.itemsMap = new Map(this.items.map((obj) => [obj.uuid, obj]));
      this.projects = projects;
      this.projectsMap = new Map(this.projects.map((obj) => [obj.uuid, obj]));
    },
    setItemFilter(filter) {
      this.itemFilter = filter;
    },
    handleChoice(uuid, option) {
      if (option === "Edit") {
        this.openModal(uuid);
      } else if (option === "Delete") {
        this.entries = U.delete("entry", this.entries, uuid);
      }
    },
    openModal(uuid) {
      this.editUuid = uuid || "";
      for (const field in this.form.fields) {
        let defaultValue, entry;
        if (this.editUuid) {
          entry = { ...this.entries.find((e) => e.uuid === this.editUuid) };
          if (field === "item") {
            entry.item = entry.itemTitle;
          } else if (field === "hourPart") {
            entry.hourPart = entry.displayTime.split(" ")[0].replace(/h/, "");
            if (entry.hourPart === "0") {
              entry.hourPart = "";
            }
          } else if (field === "minutePart") {
            entry.minutePart = entry.displayTime.split(" ")[1].replace(/m/, "");
            if (entry.minutePart[0] === "0") {
              entry.minutePart = entry.minutePart[1];
            }
            if (entry.minutePart === "0") {
              entry.minutePart = "";
            }
          }
        } else {
          defaultValue = "";
          if (field === "projectUuid") {
            defaultValue =
              this.cProjects.length === 1
                ? this.cProjects[0].value
                : window.localStorage.getItem("lastUuid") || "";
          } else if (field === "loggedFor") {
            defaultValue = U.localDate();
          }
        }
        this.form.fields[field] = entry ? entry[field] : defaultValue;
      }
      this.showModal = true;
    },
    submitForm() {
      if (this.invalidForm) return;
      let { hourPart, loggedFor, minutePart, projectUuid, item } =
        this.form.fields;
      hourPart = parseInt(hourPart) || 0;
      minutePart = parseInt(minutePart) || 0;
      if (minutePart > 59) {
        minutePart = 59;
      }
      const minutes = hourPart * 60 + minutePart;
      const obj = { loggedFor, minutes };
      const match = (x) => x.title === item && x.projectUuid === projectUuid;
      if (this.items.some(match)) {
        obj.itemUuid = this.items.find(match).uuid;
      } else {
        obj.itemUuid = U.uuid();
        obj.item = {
          uuid: obj.itemUuid,
          projectUuid,
          title: item,
          backlog: false,
          addedOn: U.localDate(),
          doneOn: U.localDate(),
          details: null,
          ms: Date.now(),
        };
        this.items.push(obj.item);
      }
      if (this.editUuid) {
        if (obj.item) {
          const plain = U.plainObject(obj);
          const body = {
            patchDoc: U.patchDoc(plain),
            item: obj.item,
          };
          const url = `api/entries/${this.editUuid}/items`;
          U.api({ url, method: "patch", body });
          this.entries = U.patchLocal(this.entries, this.editUuid, plain);
        } else {
          this.entries = U.patch("entry", this.entries, this.editUuid, obj);
        }
      } else {
        obj.createdAt = U.localTime();
        this.entries = U.post("entry", this.entries, obj);
        U.scrollToLastAdded();
        window.localStorage.setItem("lastUuid", projectUuid);
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
